@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import "@ionic/angular/css/palettes/dark.system.css";


.principal-container {
  max-width: 1600px;
  margin: auto;
}

.main-section-max-width {
  max-width: 1280px;
  margin: 0 auto;
}

.border-on-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}

.border-on-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}


.confirm-password-modal {
  --height: auto;
  --min-height: auto;
  --max-height: 90%;
  --overflow: auto;
  --border-radius: 4px;
  --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

  .modal-wrapper {
    margin: 12px;
  }
}

::ng-deep {
  .custom-toast {
    --background: var(--ion-color-success) !important;
    --color: black;
  }
}

.custom-alert {
  --alert-button-padding: 10px;
  --alert-button-color: white;
  --alert-button-border-radius: 5px;
}

.custom-alert .alert-button-group {
  display: flex;
  flex-direction: row;
}

.custom-alert .alert-button {
  cursor: pointer;
}

.custom-alert .alert-button[disabled] {
  cursor: not-allowed;
  color: gray;
}


ion-toast.budget-toast {
  --width: 600px;
  --border-radius: 10px;
}

@media screen and (max-width: 768px) {
  ion-toast.budget-toast {
    --width: 80%;
  }
}

@media screen and (max-width: 550px) {
  ion-toast.budget-toast {
    --width: 95%;
  }
}


.fields-pre-register app-profile-fields-form form .grid-principal-container-form{
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

ion-modal.platform-modal {
  --width: auto;
  --height: auto;
  --border-radius: 10px;
}
