/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  
    @apply fixed start-0 end-0 top-0 z-999 bg-transparent border-0 transition-all duration-500 ease-in-out;
    .logo {
        @apply ltr:float-left rtl:float-right text-black dark:text-white #{!important};
        .l-dark {
            @apply hidden;
        }
        .l-light {
            @apply inline-block;
        }
        &:focus {
          @apply outline-none;
        }
    }
    .has-submenu{
      @apply relative;
      &.active {
        a {
          @apply text-white dark:text-slate-900;
        }
        .submenu {
          li{
            &.active {
              > a {
                @apply text-orange-500 dark:text-orange-500 #{!important};
              }
            }
          }
        }
        &.active {
          .menu-arrow {
            @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent #{!important};
          }
        }
      }
      .submenu {
        .submenu-arrow {
          @apply absolute border-[3px] end-[1.25rem] top-[15px] border-t-transparent border-e-black dark:border-e-white border-b-black dark:border-b-white border-s-transparent rounded-[2px] inline-block ltr:-rotate-45 rtl:rotate-45;
        }
        .has-submenu {
          &:hover,
          &.active {
            > .submenu-arrow {
              @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent;
            }
          }
          .submenu {
            .has-submenu{
              &:hover {
                .submenu-arrow {
                  @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent;
                }
                > .submenu-arrow {
                  @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent;
                }
              }
            }
          }
        }
      }
    }
    .navbar-toggle {
      @apply border-0 relative p-0 m-0 cursor-pointer;
      .lines {
        @apply w-[25px] block relative h-[18px] mt-[30px] ms-[10px] me-0 mb-[26px];
      }
      span {
        transition: transform 0.5s ease;
        @apply h-[2px] w-full bg-black dark:bg-white block mb-[5px];
        &:last-child {
          @apply mb-0;
        }
      }
      &.open {
        span {
          @apply absolute;
          &:first-child {
            @apply top-[6px] rotate-45;
          }
          &:nth-child(2) {
            @apply invisible;
          }
          &:last-child {
            @apply w-full top-[6px] -rotate-45;
          }
          &:hover {
            @apply bg-orange-500;
          }
        }
      }
      &:hover, &:focus,
      .navigation-menu > li > a:hover {
        @apply bg-transparent;
      }
    }
    .buy-button {
      @apply ltr:float-right rtl:float-left leading-[74px];
      > li {
        @apply leading-[initial];
      }
      .login-btn-primary,
      .btn-icon-dark {
        @apply hidden;
      }
      .login-btn-light,
      .btn-icon-light {
        @apply inline-block;
      }
      .search-bar {
        .menu-search {
          form {
            @apply relative;
          }
        }
        .searchform {
          @apply after:absolute after:end-[14px] after:top-[14px] after:text-xl after:leading-5 after:pointer-events-none #{!important};
          input[type=text] {
            @apply shadow-none py-2.5 pe-[42px] ps-3 h-11 text-sm block outline-none #{!important};
          }
          input[type=submit] {
            @apply hidden;
          }
          &:after {
            content: "\f0349";
            font-family: "Material Design Icons";
          }
        }
      }
    }
    .buy-button,
    .buy-menu-btn {
      .dropdown {
        .dropdown-toggle {
          @apply after:hidden;
        }
      }
    }
    .navigation-menu {
      @apply list-none m-0 p-0;
      > li {
        @apply ltr:float-left rtl:float-right block relative my-0 mx-[10px];
        > a {
          @apply block text-black dark:text-white text-base bg-transparent font-medium tracking-[1px] leading-6 font-dm_sans px-[15px];
          &:hover, 
          &:active {
            @apply text-orange-500;
          }
        }
        &:hover, 
        &.active{
          > a {
            @apply text-orange-500 dark:text-orange-500 #{!important};
          }
        }
        .submenu.megamenu {
          li {
            .megamenu-head {
              @apply py-[10px] px-5 whitespace-nowrap text-xs uppercase tracking-[0.04em] font-semibold text-slate-400 #{!important};
            }
          }
        }
        .submenu {
          li {
            @apply relative mx-0;
          }
        }
      }
      .has-submenu {
        .menu-arrow {
          @apply border-[3px] border-t-transparent border-e-black dark:border-e-white border-b-black dark:border-b-white border-s-transparent rounded-[2px] inline-block ltr:rotate-45 rtl:-rotate-45 absolute transition-all duration-500 end-0 top-[33px];
        }
      }
    }
    .menu-extras {
      @apply ltr:float-right rtl:float-left;
    }
    &.scroll {
      @apply bg-white dark:bg-slate-900 border-none shadow;
      .navigation-menu {
        > li {
          > a {
            @apply text-black dark:text-white;
          }
          > .menu-arrow {
            @apply border-t-transparent border-e-black dark:border-e-white border-b-black dark:border-b-white border-s-transparent;
          }
          &:hover, 
          &.active{
            > a {
              @apply text-orange-500;
            }
            > .menu-arrow {
              @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent #{!important};
            }
          }
        }
      }
    }
    &.defaultscroll{
      &.dark-menubar {
        .logo {
          @apply leading-[70px];
        }
      }
      &.scroll {
        .logo {
          @apply leading-[62px];
        }
      }
    }
    &.nav-sticky {
      @apply bg-white/80 dark:bg-slate-900/80 backdrop-blur-md shadow dark:shadow-gray-700;
      .navigation-menu{
        &.nav-light {
          > li {
            > a {
              @apply text-black dark:text-white;
            }
            &.active {
              > a {
                @apply text-orange-500 dark:text-orange-500 #{!important};
              }
            }
            &:hover, 
            &.active{
              > .menu-arrow {
                @apply border-t-transparent border-e-orange-500 dark:border-e-orange-500 border-b-orange-500 dark:border-b-orange-500 border-s-transparent #{!important};
              }
            }
            
            &:hover, 
            &.active {
              > a {
                @apply text-orange-500 dark:text-orange-500 #{!important};
              }
            }
          }
          .has-submenu {
            .menu-arrow {
              @apply border-t-transparent border-e-black dark:border-e-white border-b-black dark:border-b-white border-s-transparent #{!important};
            }
          }
          
        }
      }
      &.tagline-height {
        @apply top-0 #{!important};
      }
      
      .buy-button {
        .login-btn-primary,
        .btn-icon-dark {
          @apply inline-block;
        }
        .login-btn-light,
        .btn-icon-light {
          @apply hidden;
        }
        .search-dropdown {
          .dark-icon {
            @apply inline-block;          
          }
          .white-icon {
            @apply hidden;          
          }
        }
      }
      
      .logo {
        .l-dark {
          @apply inline-block;
        }
        .l-light {
          @apply hidden;
        }
    }
  }
}

.logo {
  @apply font-semibold text-[24px] me-[15px] pt-0 pb-0 tracking-[1px] leading-[68px];
}

@media (min-width: 1025px) {
  #topnav {
    .navigation-menu {
      > li {
        .submenu{
          &.megamenu {
            @apply w-[1116px] #{!important};
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav {
    .navigation-menu {
      > li {
        .submenu{
          &.megamenu {
            @apply w-[936px] #{!important};
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #topnav {
    .navigation-menu {
      @apply flex flex-wrap justify-center;
      
      &.justify-end {
        > li {
          &:last-child {
            @apply me-0;
            > .sub-menu-item {
              @apply pe-0 #{!important};
            }
          }
        }
      }

      &.justify-start {
        > li {
          &:first-child {
            @apply ms-0;
          }
        }
      }

      > li {
        .submenu {
          transition: all 0.2s ease;
          @apply absolute top-full start-0 z-[1000] py-[15px] px-0 list-none min-w-[180px] invisible opacity-0 mt-[10px] rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-700;
          li {
            @apply relative ms-0;
            a {
              transition: all 0.3s;
              @apply block py-[10px] px-5 clear-both whitespace-nowrap text-[14px] tracking-[0.04em] font-normal text-black dark:text-white leading-[17px];
              &:hover {
                @apply text-orange-500 dark:text-orange-500 #{!important};
              }
            }
            ul {
              @apply list-none ps-0 m-0;
            }
          }
          &.megamenu {
            @apply whitespace-nowrap start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 fixed top-auto flex;
            > li {
              @apply overflow-hidden align-top w-1/5;
              .submenu {
                @apply start-full end-full top-0 ms-[10px] -mt-px;
              }
            }
          }
          > li {
            .submenu {
              @apply start-[101%] top-0 ms-[10px] -mt-px;
            }
          }
        }
        > a {
          @apply py-[25px] min-h-[62px];
        }
        &:hover {
          > .menu-arrow {
            @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent #{!important};
          }
        }
        &:hover, 
        &.active{
          > a {
            @apply text-orange-500 dark:text-orange-500 #{!important};
          }
        }
        &.last-elements{
          .submenu {
            @apply start-auto end-0 before:start-auto before:end-[10px];
            > li{
              &.has-submenu {
                .submenu {
                  @apply start-auto end-full ms-0 me-[10px];
                }
              }
            }
          }
        }
      }
      &.nav-light {
        > li {
          > a {
            @apply text-white/50;
          }
          &.active {
            > a {
              @apply text-white #{!important};
            }
          }
          &:hover {
            > .menu-arrow {
              @apply border-t-transparent border-e-white border-b-white border-s-transparent #{!important};
            }
            > a {
              @apply text-white #{!important};
            }
          }
        }
        .has-submenu {
          .menu-arrow {
            @apply border-t-transparent border-e-white/50 border-b-white/50 border-s-transparent #{!important};
          }
          &.active {
            .menu-arrow {
              @apply border-t-transparent border-e-white border-b-white border-s-transparent #{!important};
            }
          }
        }
      }
    }
    .buy-button {
      @apply ps-[15px] ms-[15px];
      .search-dropdown {
        .dark-icon {
          @apply hidden;          
        }
        .white-icon {
          @apply inline-block;          
        }
      }
    }
    .navbar-toggle {
      @apply hidden;
    }
    #navigation {
      @apply block #{!important};
    }
    &.scroll {
      @apply top-0;
      .navigation-menu {
        > li {
          > a {
            @apply py-5;
          }
        }
      }
    }
    &.scroll-active {
      .navigation-menu {
        > li {
          > a {
            @apply py-[25px];
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #topnav {
    @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 min-h-[74px];
    .logo {
      .l-dark {
        @apply inline-block #{!important};
      }
      .l-light {
        @apply hidden #{!important};
      }
    }
    .container {
      @apply w-auto;
    }
    #navigation {
      @apply max-h-[400px] shadow;
    }
    .navigation-menu {
      @apply float-none #{!important};
      > li {
        @apply float-none #{!important};
        .submenu {
          @apply hidden list-none ps-5 m-0;
          li {
            a {
              transition: all 0.3s;
              @apply block relative py-[7px] px-[15px] tracking-[0.04em] text-black dark:text-white #{!important};
            }
          }
          &.megamenu {
            li {
              .megamenu-head {
                @apply py-[7px] px-[15px];
              }
            }
            > li {
              > ul {
                @apply list-none ps-0;
                > li {
                  > span {
                    @apply block relative py-[10px] px-[15px] uppercase text-xs tracking-[2px] text-slate-200;
                  }
                }
              }
            }
          }
          &.open {
            @apply block;
          }
          .submenu {
            @apply hidden list-none;
            &.open {
              @apply block;
            }
          }
        }
        > a {
          @apply text-black dark:text-white py-[10px] px-[20px] after:absolute after:end-[15px];
        }
        > a:hover,
        .submenu li a:hover,
        &.has-submenu.open > a {
          @apply text-orange-500;
        }
      }
    }
    .menu-extras {
      .menu-item {
        @apply border-gray-200 dark:border-gray-700;
      }
    }
    .navbar-header {
      @apply ltr:float-left rtl:float-right;
    }

    .buy-button {
      .login-btn-primary,
      .btn-icon-dark {
        @apply inline-block #{!important};
      }
      .login-btn-light,
      .btn-icon-light {
        @apply hidden;
      }
      .search-dropdown {
        .dark-icon {
          @apply inline-block;          
        }
        .white-icon {
          @apply hidden;          
        }
      }
    }
    .has-submenu {
      .submenu {
        .submenu-arrow {
          @apply ltr:rotate-45 absolute end-5 top-3;
        }
      }
      &.active {
        a {
          @apply text-orange-500;
        }
      }
    }
  }
  
  #navigation {
    @apply absolute top-[74px] start-0 w-full hidden h-auto bg-white dark:bg-slate-900 overflow-auto;
    &.open {
      @apply block overflow-y-auto;
    }
  }
}

@media (max-width: 768px) {
  #topnav {
    .navigation-menu {
      .has-submenu {
        .menu-arrow {
          @apply end-2 top-4;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  #topnav {
    .navigation-menu {
      > li{
        &.has-submenu{
          &:hover {
            > .submenu {
              @apply visible opacity-100 mt-0;
              > li{
                &.has-submenu{
                  &:hover {
                    > .submenu {
                      @apply visible opacity-100 ms-0 me-0;
                      > li{
                        &:hover {
                          > .submenu {
                            @apply visible opacity-100 ms-0 me-0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .navbar-toggle {
    @apply block;
  }
}

@media (max-width: 425px) {
  #topnav {
    .buy-menu-btn {
      @apply block my-0 mx-[10px] py-[10px] px-5 #{!important};
      .dropdown {
        .dropdown-menu{
          &.show {
            transform: translate3d(0px, -54px, 0px) !important;
          }
        }
      }
    }
  }
}
.tagline {
    @apply absolute w-full z-[99] text-sm py-[13px] px-0;
}
@media screen and (max-width: 575px) {
    .tagline {
        @apply hidden;
    }
    .tagline-height {
        @apply top-0 #{!important}; 
    }
}
@media (min-width: 576px) {
  .tagline-height {
    @apply top-[47px] #{!important};
  }
}
  
.sidebar-nav {
    @apply py-[15px] px-0;
        > .navbar-item {
          @apply py-[3px] px-[15px];
          .navbar-link {
            @apply text-[15px] font-semibold;
            .navbar-icon {
              @apply text-lg me-[6px];
            }
          }
          &:hover,
          &.active {
            .navbar-link {
              @apply text-orange-500;
            }
          }
    }
    li{
      &.active {
        a {
          @apply text-orange-500;
        }
      }
      &.account-menu.active,
      &.account-menu:hover{
        .navbar-link {
          @apply text-orange-500 dark:text-white #{!important};
        }
      }
    }
}

.text-orange-500{
  color: var(--ion-color-primary)
}