/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  @apply fixed start-0 end-0 top-0 z-999 bg-transparent border-0 transition-all duration-500 ease-in-out;
}
#topnav .logo {
  @apply ltr:float-left rtl:float-right text-black dark:text-white !important;
}
#topnav .logo .l-dark {
  @apply hidden;
}
#topnav .logo .l-light {
  @apply inline-block;
}
#topnav .logo:focus {
  @apply outline-none;
}
#topnav .has-submenu {
  @apply relative;
}
#topnav .has-submenu.active a {
  @apply text-white dark:text-slate-900;
}
#topnav .has-submenu.active .submenu li.active > a {
  @apply text-orange-500 dark:text-orange-500 !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent !important;
}
#topnav .has-submenu .submenu .submenu-arrow {
  @apply absolute border-[3px] end-[1.25rem] top-[15px] border-t-transparent border-e-black dark:border-e-white border-b-black dark:border-b-white border-s-transparent rounded-[2px] inline-block ltr:-rotate-45 rtl:rotate-45;
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow, #topnav .has-submenu .submenu .has-submenu.active > .submenu-arrow {
  @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent;
}
#topnav .navbar-toggle {
  @apply border-0 relative p-0 m-0 cursor-pointer;
}
#topnav .navbar-toggle .lines {
  @apply w-[25px] block relative h-[18px] mt-[30px] ms-[10px] me-0 mb-[26px];
}
#topnav .navbar-toggle span {
  transition: transform 0.5s ease;
  @apply h-[2px] w-full bg-black dark:bg-white block mb-[5px];
}
#topnav .navbar-toggle span:last-child {
  @apply mb-0;
}
#topnav .navbar-toggle.open span {
  @apply absolute;
}
#topnav .navbar-toggle.open span:first-child {
  @apply top-[6px] rotate-45;
}
#topnav .navbar-toggle.open span:nth-child(2) {
  @apply invisible;
}
#topnav .navbar-toggle.open span:last-child {
  @apply w-full top-[6px] -rotate-45;
}
#topnav .navbar-toggle.open span:hover {
  @apply bg-orange-500;
}
#topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover {
  @apply bg-transparent;
}
#topnav .buy-button {
  @apply ltr:float-right rtl:float-left leading-[74px];
}
#topnav .buy-button > li {
  @apply leading-[initial];
}
#topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
  @apply hidden;
}
#topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
  @apply inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  @apply relative;
}
#topnav .buy-button .search-bar .searchform {
  @apply after:absolute after:end-[14px] after:top-[14px] after:text-xl after:leading-5 after:pointer-events-none !important;
}
#topnav .buy-button .search-bar .searchform input[type=text] {
  @apply shadow-none py-2.5 pe-[42px] ps-3 h-11 text-sm block outline-none !important;
}
#topnav .buy-button .search-bar .searchform input[type=submit] {
  @apply hidden;
}
#topnav .buy-button .search-bar .searchform:after {
  content: "\f0349";
  font-family: "Material Design Icons";
}
#topnav .buy-button .dropdown .dropdown-toggle,
#topnav .buy-menu-btn .dropdown .dropdown-toggle {
  @apply after:hidden;
}
#topnav .navigation-menu {
  @apply list-none m-0 p-0;
}
#topnav .navigation-menu > li {
  @apply ltr:float-left rtl:float-right block relative my-0 mx-[10px];
}
#topnav .navigation-menu > li > a {
  @apply block text-black dark:text-white text-base bg-transparent font-medium tracking-[1px] leading-6 font-dm_sans px-[15px];
}
#topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
  @apply text-orange-500;
}
#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
  @apply text-orange-500 dark:text-orange-500 !important;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  @apply py-[10px] px-5 whitespace-nowrap text-xs uppercase tracking-[0.04em] font-semibold text-slate-400 !important;
}
#topnav .navigation-menu > li .submenu li {
  @apply relative mx-0;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  @apply border-[3px] border-t-transparent border-e-black dark:border-e-white border-b-black dark:border-b-white border-s-transparent rounded-[2px] inline-block ltr:rotate-45 rtl:-rotate-45 absolute transition-all duration-500 end-0 top-[33px];
}
#topnav .menu-extras {
  @apply ltr:float-right rtl:float-left;
}
#topnav.scroll {
  @apply bg-white dark:bg-slate-900 border-none shadow;
}
#topnav.scroll .navigation-menu > li > a {
  @apply text-black dark:text-white;
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  @apply border-t-transparent border-e-black dark:border-e-white border-b-black dark:border-b-white border-s-transparent;
}
#topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
  @apply text-orange-500;
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
  @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent !important;
}
#topnav.defaultscroll.dark-menubar .logo {
  @apply leading-[70px];
}
#topnav.defaultscroll.scroll .logo {
  @apply leading-[62px];
}
#topnav.nav-sticky {
  @apply bg-white/80 dark:bg-slate-900/80 backdrop-blur-md shadow dark:shadow-gray-700;
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  @apply text-black dark:text-white;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-orange-500 dark:text-orange-500 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  @apply border-t-transparent border-e-orange-500 dark:border-e-orange-500 border-b-orange-500 dark:border-b-orange-500 border-s-transparent !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-orange-500 dark:text-orange-500 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  @apply border-t-transparent border-e-black dark:border-e-white border-b-black dark:border-b-white border-s-transparent !important;
}
#topnav.nav-sticky.tagline-height {
  @apply top-0 !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  @apply inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  @apply hidden;
}
#topnav.nav-sticky .buy-button .search-dropdown .dark-icon {
  @apply inline-block;
}
#topnav.nav-sticky .buy-button .search-dropdown .white-icon {
  @apply hidden;
}
#topnav.nav-sticky .logo .l-dark {
  @apply inline-block;
}
#topnav.nav-sticky .logo .l-light {
  @apply hidden;
}

.logo {
  @apply font-semibold text-[24px] me-[15px] pt-0 pb-0 tracking-[1px] leading-[68px];
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[1116px] !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[936px] !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    @apply flex flex-wrap justify-center;
  }
  #topnav .navigation-menu.justify-end > li:last-child {
    @apply me-0;
  }
  #topnav .navigation-menu.justify-end > li:last-child > .sub-menu-item {
    @apply pe-0 !important;
  }
  #topnav .navigation-menu.justify-start > li:first-child {
    @apply ms-0;
  }
  #topnav .navigation-menu > li .submenu {
    transition: all 0.2s ease;
    @apply absolute top-full start-0 z-[1000] py-[15px] px-0 list-none min-w-[180px] invisible opacity-0 mt-[10px] rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-700;
  }
  #topnav .navigation-menu > li .submenu li {
    @apply relative ms-0;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block py-[10px] px-5 clear-both whitespace-nowrap text-[14px] tracking-[0.04em] font-normal text-black dark:text-white leading-[17px];
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    @apply text-orange-500 dark:text-orange-500 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    @apply list-none ps-0 m-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply whitespace-nowrap start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 fixed top-auto flex;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    @apply overflow-hidden align-top w-1/5;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    @apply start-full end-full top-0 ms-[10px] -mt-px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    @apply start-[101%] top-0 ms-[10px] -mt-px;
  }
  #topnav .navigation-menu > li > a {
    @apply py-[25px] min-h-[62px];
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    @apply border-t-transparent border-e-orange-500 border-b-orange-500 border-s-transparent !important;
  }
  #topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
    @apply text-orange-500 dark:text-orange-500 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    @apply start-auto end-0 before:start-auto before:end-[10px];
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    @apply start-auto end-full ms-0 me-[10px];
  }
  #topnav .navigation-menu.nav-light > li > a {
    @apply text-white/50;
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    @apply border-t-transparent border-e-white border-b-white border-s-transparent !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    @apply border-t-transparent border-e-white/50 border-b-white/50 border-s-transparent !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    @apply border-t-transparent border-e-white border-b-white border-s-transparent !important;
  }
  #topnav .buy-button {
    @apply ps-[15px] ms-[15px];
  }
  #topnav .buy-button .search-dropdown .dark-icon {
    @apply hidden;
  }
  #topnav .buy-button .search-dropdown .white-icon {
    @apply inline-block;
  }
  #topnav .navbar-toggle {
    @apply hidden;
  }
  #topnav #navigation {
    @apply block !important;
  }
  #topnav.scroll {
    @apply top-0;
  }
  #topnav.scroll .navigation-menu > li > a {
    @apply py-5;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    @apply py-[25px];
  }
}
@media (max-width: 991px) {
  #topnav {
    @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 min-h-[74px];
  }
  #topnav .logo .l-dark {
    @apply inline-block !important;
  }
  #topnav .logo .l-light {
    @apply hidden !important;
  }
  #topnav .container {
    @apply w-auto;
  }
  #topnav #navigation {
    @apply max-h-[400px] shadow;
  }
  #topnav .navigation-menu {
    @apply float-none !important;
  }
  #topnav .navigation-menu > li {
    @apply float-none !important;
  }
  #topnav .navigation-menu > li .submenu {
    @apply hidden list-none ps-5 m-0;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block relative py-[7px] px-[15px] tracking-[0.04em] text-black dark:text-white !important;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    @apply py-[7px] px-[15px];
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    @apply list-none ps-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    @apply block relative py-[10px] px-[15px] uppercase text-xs tracking-[2px] text-slate-200;
  }
  #topnav .navigation-menu > li .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    @apply hidden list-none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li > a {
    @apply text-black dark:text-white py-[10px] px-[20px] after:absolute after:end-[15px];
  }
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover, #topnav .navigation-menu > li.has-submenu.open > a {
    @apply text-orange-500;
  }
  #topnav .menu-extras .menu-item {
    @apply border-gray-200 dark:border-gray-700;
  }
  #topnav .navbar-header {
    @apply ltr:float-left rtl:float-right;
  }
  #topnav .buy-button .login-btn-primary,
  #topnav .buy-button .btn-icon-dark {
    @apply inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
  #topnav .buy-button .btn-icon-light {
    @apply hidden;
  }
  #topnav .buy-button .search-dropdown .dark-icon {
    @apply inline-block;
  }
  #topnav .buy-button .search-dropdown .white-icon {
    @apply hidden;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    @apply ltr:rotate-45 absolute end-5 top-3;
  }
  #topnav .has-submenu.active a {
    @apply text-orange-500;
  }
  #navigation {
    @apply absolute top-[74px] start-0 w-full hidden h-auto bg-white dark:bg-slate-900 overflow-auto;
  }
  #navigation.open {
    @apply block overflow-y-auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    @apply end-2 top-4;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 mt-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 ms-0 me-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu > li:hover > .submenu {
    @apply visible opacity-100 ms-0 me-0;
  }
  .navbar-toggle {
    @apply block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    @apply block my-0 mx-[10px] py-[10px] px-5 !important;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  @apply absolute w-full z-[99] text-sm py-[13px] px-0;
}

@media screen and (max-width: 575px) {
  .tagline {
    @apply hidden;
  }
  .tagline-height {
    @apply top-0 !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    @apply top-[47px] !important;
  }
}
.sidebar-nav {
  @apply py-[15px] px-0;
}
.sidebar-nav > .navbar-item {
  @apply py-[3px] px-[15px];
}
.sidebar-nav > .navbar-item .navbar-link {
  @apply text-[15px] font-semibold;
}
.sidebar-nav > .navbar-item .navbar-link .navbar-icon {
  @apply text-lg me-[6px];
}
.sidebar-nav > .navbar-item:hover .navbar-link, .sidebar-nav > .navbar-item.active .navbar-link {
  @apply text-orange-500;
}
.sidebar-nav li.active a {
  @apply text-orange-500;
}
.sidebar-nav li.account-menu.active .navbar-link, .sidebar-nav li.account-menu:hover .navbar-link {
  @apply text-orange-500 dark:text-white !important;
}

.text-orange-500 {
  color: var(--ion-color-primary);
}